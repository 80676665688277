import axios from "axios";

export const chk_biznumApi = async biznum => {
  let res;
  try {
    res = await axios.get(
      `/api/user/check/mart/${biznum}`
    );
    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.error("chk_biznumApi", err);
    return false;
  }
};

export const get_geoApi = async addr => {
  let res;
  try {
    res = await axios.get(`/api/geo/${addr}`);
    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.error("get_geoApi", err);
    return false;
  }
};

export const post_userApi = async user_data => {
  let res;
  try {
    res = await axios.post(`/api/user/mart`,
      user_data
    );
    if (res.status === 200) {
      console.log("[success] post_userApi");
      return res.data;
    }
  } catch (err) {
    console.error("post_userApi", err);
    return false;
  }
};
