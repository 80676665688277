<template>
  <div class="form-group">
    <label>업체전화번호</label>
    <input
      type="text"
      class="form-control"
      id="input-tel"
      placeholder="'-'없이 입력해주세요"
      :value="signUpObj.tel"
      @input="set_tel"
      :class="{ 'is-invalid': set_validation() }"
    />
    <div v-if="set_validation()" class="invalid-feedback">
      {{ d_invalidText }}
    </div>
  </div>
</template>

<script>
import { signUpComputed, signUpMethods } from "@/state/modules/signUpHelper";
import { regexNum } from "@/views/pages/account/signup/atom/regex";
export default {
  name: "TelForm",
  data: () => ({
    d_IsChanged: false,
    d_invalidText: ""
  }),

  computed: { ...signUpComputed },
  methods: {
    ...signUpMethods,
    validator(val) {
      this.setTelValidAct({ payload: val });
    },
    set_validation() {
      return (
        (this.signUpValid.submit || this.d_IsChanged) && !this.signUpValid.tel
      );
    },
    chk_valid(valid, msg) {
      this.validator(valid);
      this.d_invalidText = msg;
    },
    set_tel(e) {
      this.d_IsChanged = true;
      e.target.value = e.target.value.replace(regexNum, "");
      if (e.target.value.length === 0) {
        this.chk_valid(false, "업체전화번호를 입력해주세요.");
      } else {
        this.chk_valid(true);
      }
      this.setTelAct({ payload: e.target.value });
    }
  }
};
</script>
